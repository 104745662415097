<script>
import { Components } from "manageplaces-ui-kit";
import usersQuery from "@/graphql/queries/Users.gql";

export default {
  extends: Components.BaseTable,
  apollo: {
    users: {
      query: usersQuery,
      update(data) {
        return data.users.edges.map(edge => edge.node);
      },
      result() {
        this.setRowData(this.users);
        this.stopLoading();
      },
    },
  },
  data() {
    return {
      columns: [
        { headerName: "Name", field: "name", flex: 1  },
        {
          headerName: "Invited by",
          field: "invitedBy",
          cellRenderer: "teamMember",
        },
        { headerName: "Invited at", field: "invitationSentAt", cellRenderer: "date",
          cellRendererParams: {
          time: true
        }
        },
        { headerName: "Last activity", field: "lastActivity", cellRenderer: "date",
          cellRendererParams: {
          time: true
        }
        },
      ],
      users: [],
    };
  },
  mounted() {
    this.startLoading();
  },
  methods: {
    performSearch(evt) {
      this.$emit("search", evt.target.value);
    },
  },
};
</script>
