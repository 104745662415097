<template lang="pug">
  superadmin-table(domLayout="autoHeight", :actionBar="false")
    template(slot="buttons")
      app-button(@click="$router.push('/users/new')", primary) Add user

</template>
<script>
import SuperadminTable from "@/components/tables/SuperadminUsersTable.vue";

export default {
  components: {
    SuperadminTable,
  },
};
</script>
